/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';
@import "primeicons/primeicons.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.app-spacer{
  flex: 1 1 auto;
}
.app-full-width{
  width: 100%;
  margin-bottom: -1.25em !important;
}
.app-nav-bar{
  font-size: medium;
}

.app-toolbar {
  height: 35px;
  font-size: 16px;
  max-width: 100% !important;
  min-width: 98% !important;
}
.app-toolbar button {
  margin-right: 25px;
  margin-left: 0px;
}
.app-dialog-title{
  font-size: large;
  color: darkgreen;
}
.app-locked-item{
  text-decoration: line-through dotted red;
}
.row-disabled{
  background-color:#dddddd !important;
}
.item-disabled{
  background-color:#dddddd !important;
}
.item-right{
  text-align: right;
}
form {
  mat-form-field {
    width: 95vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.center {
  text-align: center;
}


.grid-container{
  display: grid;
  width: 100%;
  column-gap: 0.1em;
  padding: 0px;
  margin: 0px;
  grid-row-gap: 1px !important;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.grid-col-2{
  grid-column: span 2;
}
.grid-col-3{
  grid-column: span 3;
}
.grid-col-4{
  grid-column: span 4;
}
.grid-col-5{
  grid-column: span 5;
}
.grid-col-6{
  grid-column: span 6;
}
.grid-col-7{
  grid-column: span 7;
}
.grid-col-8{
  grid-column: span 8;
}
.grid-col-9{
  grid-column: span 9;
}
.grid-col-10{
  grid-column: span 10;
}
mat-form-field{
  width: 95%;
}
.mat-form-field-no-padding .mat-form-field-wrapper {
  margin-bottom: -1.25em !important;
}

.search-table-form {
  min-width: 150px !important;
  max-width: 500px !important;
  width: 100%;
}

// .p-datatable-wrapper {
//   padding-bottom: 100px;
// }

//For p-table
.td-left{
  text-align: left;
}
.td-right{
  text-align: right !important;
}
.td-center{
  text-align: center;
}
